<template>
  <div class="registersection bg-light">
    <el-page-header @back="goBack" content="请注册!">
    </el-page-header>
    <div class="stepline animated slideInLeft">
      <el-steps :active="active" finish-status="success">
        <el-step title="Step 1" description="Get Verification Code"></el-step>
        <el-step title="Step 2" description="Verify Email"></el-step>
        <el-step title="Step 3" description="Fill Information"></el-step>
        <el-step title="Step 4" description="Complete"></el-step>
      </el-steps>
    </div>
    <div class="registerContainer animated zoomIn">
      <el-row justify="center" style="height: 330px">
        <el-col :span="18" class="registerarea" offset="3">
          <router-view @func="getMsgFromSon" @funct="getPassword" class="forms" v-loading="loading"></router-view>
        </el-col>
      </el-row>
      <div class="nextBtnSec">
        <el-divider>
          <el-button type="primary" class="nextbtn" @click="next">{{ buttonName }}</el-button>
        </el-divider>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'RegisterPage',
  data () {
    return {
      loginID: '',
      verificationCode: '',
      active: 0,
      buttonName: '获得验证码',
      Key: '',
      password: '',
      loading: false
    }
  },
  methods: {
    getMsgFromSon (data) {
      this.Key = data
      console.log(this.Key)
    },
    getPassword (data) {
      this.password = data
    },
    goBack () {
      this.$router.push({ path: '/login' })
    },
    next () {
      const step = this.active + 1
      if (step === 1) {
        this.$message({
          message: '正在发送验证码...',
          duration: 2000
        })
        this.loading = true
        axios({
          method: 'POST',
          url: 'https://mooc.kirinnorine.top/student/getRegisterVerificationCode',
          params: {
            loginId: this.Key
          }
        }).then((response) => {
          if (response.data.status) {
            this.goToNext()
            this.buttonName = '确定'
            this.loginID = this.Key
            this.loading = false
            if (this.loginID.includes('@')) {
              this.$message.success('验证码发送成功，请前往邮箱查看，5分钟内有效')
            } else {
              this.$message.success('验证码发送成功，请前往手机查看，5分钟内有效')
            }
          } else {
            this.loading = false
            this.$message.error(response.data.message)
          }
        })
      } else if (step === 2) {
        this.loading = true
        axios({
          method: 'POST',
          url: 'https://mooc.kirinnorine.top/student/checkRegisterVerificationCode',
          params: {
            loginId: this.loginID,
            verificationCode: this.Key
          }
        }).then((response) => {
          if (response.data.status) {
            this.loading = false
            this.goToNext()
            this.buttonName = '提交'
            this.verificationCode = this.Key
            this.$message.success('验证成功')
          } else {
            this.loading = false
            this.$message.error(response.data.message)
          }
        })
      } else if (step === 3) {
        this.loading = true
        axios({
          method: 'POST',
          url: 'https://mooc.kirinnorine.top/student/registerWithVerificationCode',
          params: {
            loginId: this.loginID,
            password: this.password,
            name: this.Key,
            verificationCode: this.verificationCode
          }
        }).then((response) => {
          if (response.data.status) {
            this.$message.success('注册成功')
            this.loading = false
            this.buttonName = '前往首页'
            this.goToNext()
          } else {
            this.loading = false
            this.$message.error(response.data.message)
          }
        })
      } else if (step === 4) {
        this.goToNext()
      } else {
        alert('Exception Occurs')
      }
    },
    goToNext () {
      this.active += 1
      if (this.active > 3) {
        this.$router.push({ path: '/' })
      } else {
        this.$router.replace({ path: '/step' + (this.active + 1) })
      }
    }
  },
  components: {}
}
</script>
<style scoped>
.registersection {
  min-height: 100vh;
  margin: 0;
  padding-top: 3%;
  padding-left: 10%;
  padding-right: 10%;
  background: url("https://cos.mooc.huangzhaokun.top/moocImg/index01.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.registerContainer {
  width: 800px;
  height: 400px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.stepline {
  width: 55%;
  margin: 30px auto;
}

.nextbtn {
  width: 100%;
}

.registerarea {
  vertical-align: center;
}

.forms {
  margin-left: auto;
  margin-right: auto;
}

.nextBtnSec {
  width: 75%;
  margin: 10px auto;
}
</style>
